// -----------------------------------//
// Variables
// -----------------------------------//

// gold #92834a
// gold dark #7c6f3d
// green #1c593d
// green dark #144b32

// -----------------------------------//
// Sup Nav colours
// -----------------------------------//
$sup-menu-bg: #349966;
$sup-menu-highlight: #338C5E;
$sup-menu-colour: #fff;

// -----------------------------------//
// Nav Menu colours
// -----------------------------------//
$menu-background-bg: #01006A;
$menu-text-colour: #fff;
$sub-menu-bg: #01006A;

// Hovers
$menu-hover: #0101FF;
$sub-menu-hover: #0101FF;

//MEANMENU
$mm-reveal-background: #01006A;
$mm-navigation-background: #01006A;

// -----------------------------------//
// HEADER LOGO SECTION
// -----------------------------------//
$header-background: #fff;
$logo-colour: #349966;

// -----------------------------------//
// NEWS DIARY SECTION
// -----------------------------------//

$scroll-hvr: #349966; //twitter scrollbar hover
$date-bg: #349966; // diary date bg
$date-box-bg: #01006A; // diary entry bg

// buttons
$btn-bg: #349966;
$btn-hvr: #349966;
 
// -----------------------------------//
// FOOTER colours
// -----------------------------------//
$footer-bg: #fff;
$footer-colour: #000069;
$footer-link: #000069;
$footer-link-hvr: #349966;
$footer-register: #1c593d;

//subfooter
$subfooter-bg: #349966;
$subfooter-link: #fff;
$subfooter-link-hvr: #fff;


// -----------------------------------//
// INSIDE PAGES
// -----------------------------------//
$link-color: deepskyblue;
$h1-color: #000069;
$h2-color: #349966;
$h3-bg-color:#349966;
$h4-bg-color:#000069;

//Related pages
$related-pages-h2:#fff;
$related-pages-bg: #349966;
$related-pages-hvr:#1c593d;


//News
$little-date-line: #349966;

//Subscribe
$subscribe-bg-colour: #349966;

//Diary list inside page colours
$diary-date-bg: #000069;
$add-to-calendar-bg: #349966;

//Gallery
$gallery-bg: #000069;

//Photo box text colours (DARK background)
// Date
$box-light-date: #dbed2b;

//Photo box text colours (LIGHT background)
// Date
$box-dark-date: #000069;

//Grid and List view styles
h2.ListingHead {
	background: #000069;
}

// -----------------------------------//
// SEARCH RESULTS
// -----------------------------------//
$search-highlight-background-color: #000069;
$search-highlight-color: #fff;

// -----------------------------------//
// CMS CONTENT: TABLES
// -----------------------------------//
$table-highlight-color: #fff;
$table-highlight-background-color: #000069;
$table-border-color: #000069;

// -----------------------------------//
// CMS CONTENT: BLOCKQUOTES
// -----------------------------------//
$blockquote-background-color: #fff;
$blockquote-color: #000069;
$blockquote-speech-mark-color: #349966;
$blockquote-border-color: #349966;